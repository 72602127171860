.background-container {
  position: relative;
}

.background-container::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  pointer-events: none;
}

.background-container * {
  position: relative;
  /* z-index: 1; */
}
